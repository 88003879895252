const menu_data = [
  {
    id: 1,
    mega_menu: false,
    has_dropdown: false,
    title: "Trang chủ",
    link: "/",
    active: "active",
  },
  {
    id: 2,
    mega_menu: false,
    has_dropdown: false,
    title: "Học tập",
    link: "/quiz-play",
    active: "",
  },
  // {
  //   id: 3,
  //   mega_menu: false,
  //   has_dropdown: false,
  //   title: "Câu hỏi đã lưu",
  //   link: "/bookmark",
  //   active: "",
  // },
  // {
    {
    id: 3,
    mega_menu: false,
    has_dropdown: false,
    title: "Du lịch Điện Biên",
    link: "https://dulichdienbien.site/",
    active: "",
  },
  {
    id: 4,
    mega_menu: false,
    has_dropdown: false,
    title: "Mời bạn bè",
    link: "/invite-friends",
    active: "",
  },
  {
    id: 5,
    mega_menu: false,
    has_dropdown: false,
    title: "Hướng dẫn",
    link: "/instruction",
    active: "",
  },
  {
    id: 6,
    mega_menu: false,
    has_dropdown: false,
    title: "Bảng xếp hạng",
    link: "/leaderboard",
    active: "",
  },
  {
    id: 7,
    mega_menu: false,
    has_dropdown: true,
    title: "Xem thêm",
    link: "/",
    active: "",
    sub_menus: [
      { link: "/contact-us", title: "Liên hệ" },
      { link: "/about-us", title: "Giới thiệu" },
      { link: "/terms-conditions", title: "Điều khoản và điều kiện" },
      { link: "/privacy-policy", title: "Chính sách bảo mật" },
    ],
  },
];
export default menu_data;
